import { langs } from "@/locale"
import Head from "next/head"
import { useMemo } from "react"

export const HeadLegal = props => {
  const { fields } = props
  const { hostname, platform } = fields
  const texts = useMemo(() => langs[platform.codeLang?.toLowerCase()], [platform])
  return (
    <Head>
      <title>{`${hostname?.business?.alias} - ${texts.title.text6}`}</title>
      <meta name="description" content={texts.meta.legal} />
    </Head>
  )
}
