import { cmp } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { Box, Grid2, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import parse from "html-react-parser"
import { useId } from "react"

const LegalTable = ({ ...props }) => {
  const keyId = useId()
  return (
    <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
      <Table aria-label={props.aria}>
        <TableBody>
          {props.data.map((x, i) => (
            <TableRow key={handleKeyRandom(keyId, i)}>
              <TableCell cmptype={cmp.stickyCell} align="right">
                {x.camp}
              </TableCell>
              <TableCell cmptype={cmp.styleCell} align="left">
                {parse(x.text)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const BodyLegalBYM = props => {
  const { hostname, privacyTexts, media } = props
  const { mobile } = hostname
  const keyId = useId()
  return (
    <Grid2
      container
      sx={{
        minHeight: !mobile ? `85vh` : `100vh`,
        alignContent: `flex-start`,
      }}
    >
      <Grid2 cmptype={cmp.gridLegal} size={{ xs: 12, md: media || 10 }}>
        <Typography cmptype={cmp.legalTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.text)}
        </Typography>
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.acept.title)}
        </Typography>
        {
          privacyTexts.purchase_terms.acept.text.map((x, i) => <Typography cmptype={cmp.legalText} gutterBottom key={handleKeyRandom(`text`, i)}>
            {parse(x)}
          </Typography>)
        }
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.communications.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.communications.text)}
        </Typography>
        <Box>
          <ul >
            {privacyTexts.purchase_terms.communications.data.map((b, e) => (
              <li key={`li_${handleKeyRandom(keyId, e)}`}>
                <Typography cmptype={cmp.legalText} gutterBottom>
                  {parse(b)}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.communications.text2)}
        </Typography>
        <Box>
          <ul >
            {privacyTexts.purchase_terms.communications.data2.map((b, e) => (
              <li key={`li_${handleKeyRandom(keyId, e)}`}>
                <Typography cmptype={cmp.legalText} gutterBottom>
                  {parse(b)}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.communications.text3)}
        </Typography>

        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.access.title)}
        </Typography>

        {
          privacyTexts.purchase_terms.access.text.map((x, i) => <Typography cmptype={cmp.legalText} gutterBottom key={handleKeyRandom(`text`, i)}>
            {parse(x)}
          </Typography>)
        }
        <Box>
          <ul >
            {privacyTexts.purchase_terms.access.data.map((b, e) => (
              <li key={`li_${handleKeyRandom(keyId, e)}`}>
                <Typography cmptype={cmp.legalText} gutterBottom>
                  {parse(b)}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
        {
          privacyTexts.purchase_terms.access.text2.map((x, i) => <Typography cmptype={cmp.legalText} gutterBottom key={handleKeyRandom(`text`, i)}>
            {parse(x)}
          </Typography>)
        }
        <Box>
          <ul >
            {privacyTexts.purchase_terms.access.data2.map((b, e) => (
              <li key={`li_${handleKeyRandom(keyId, e)}`}>
                <Typography cmptype={cmp.legalText} gutterBottom>
                  {parse(b)}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>

        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.process.title)}
        </Typography>

        {
          privacyTexts.purchase_terms.process.text.map((x, i) => <Typography cmptype={cmp.legalText} gutterBottom key={handleKeyRandom(`text`, i)}>
            {parse(x)}
          </Typography>)
        }
        <Typography cmptype={cmp.legalTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.terms.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.terms.text)}
        </Typography>
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.prices.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.prices.text)}
        </Typography>
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.invoicing.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.invoicing.text)}
        </Typography>
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.cancel.title)}
        </Typography>
        {
          privacyTexts.purchase_terms.cancel.text.map((x, i) => <Typography cmptype={cmp.legalText} gutterBottom key={handleKeyRandom(`text`, i)}>
            {parse(x)}
          </Typography>)
        }
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.claims.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.claims.text)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.claims.link)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.claims.text2)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.claims.link2)}
        </Typography>

        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.warranty.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.warranty.text)}
        </Typography>
        <Box>
          <ul >
            {privacyTexts.purchase_terms.warranty.data.map((b, e) => (
              <li key={`li_${handleKeyRandom(keyId, e)}`}>
                <Typography cmptype={cmp.legalText} gutterBottom>
                  {parse(b)}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.rights.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.rights.text)}
        </Typography>
        {/* <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.purchase_terms.note.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.purchase_terms.note.text)}
        </Typography> */}

        <Typography cmptype={cmp.legalTitle} gutterBottom>
          {parse(privacyTexts.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.text)}
        </Typography>
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.owner.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.owner.text)}
        </Typography>
        <LegalTable aria="table-owner" data={privacyTexts.owner.data} />
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.newsletter.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.newsletter.text)}
        </Typography>
        <LegalTable aria="table-newsletter" data={privacyTexts.newsletter.data} />
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.account.title)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.account.text)}
        </Typography>
        <LegalTable aria="table-account" data={privacyTexts.account.data} />
        <Typography cmptype={cmp.legalSubTitle} gutterBottom>
          {parse(privacyTexts.treatment.title)}
        </Typography>
        <Typography cmptype={cmp.legalSubDoubleTitle} gutterBottom>
          {parse(privacyTexts.treatment.subtitle)}
        </Typography>
        <Typography cmptype={cmp.legalText} gutterBottom>
          {parse(privacyTexts.treatment.text)}
        </Typography>
        {privacyTexts.treatment.list.map((x, i) => (
          <Box key={`box_${handleKeyRandom(keyId, i)}`}>
            <Typography cmptype={cmp.legalSubTitle} gutterBottom>
              {parse(x.subTitle)}
            </Typography>
            {x.text.map((b, e) => typeof b === `string` ? (
              <Typography cmptype={cmp.legalText} key={`txt_${handleKeyRandom(keyId, e)}`} gutterBottom>
                {parse(b)}
              </Typography>
            ) : (
              <ul key={`ul_${handleKeyRandom(keyId, e)}`} style={{ listStyle: `lower-latin` }}>
                {b.map((c, f) => (
                  <li key={`li_${handleKeyRandom(keyId, f)}`}>
                    <Typography cmptype={cmp.legalText} gutterBottom>
                      {parse(c)}
                    </Typography>
                  </li>
                ))}
              </ul>
            ))}
          </Box>
        ))}
      </Grid2>
    </Grid2>
  )
}
