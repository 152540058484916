import { BodyLegal, BodyLegalBYM } from "@/app/_components/legal"
import { cmp } from "@/types"
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide
} from "@mui/material"
import { forwardRef, memo, useId } from "react"
import { IoMdClose } from "react-icons/io"

const Transition = forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />
})

export const LegalModal = memo(({ open, setOpen, ...props }) => {
  const { colors, business } = props.hostname
  const handleClose = () => {
    setOpen(false)
  }
  const idModal = useId()
  return (
    <Dialog
      cmptype={cmp.modal}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      disableEnforceFocus
      id={idModal}
      fullWidth={!props.mobile && true}
      maxWidth="lg"
      fullScreen={props.mobile}
      scroll="paper"
    >
      <AppBar sx={{
        position: `relative`,
        boxShadow: 0,
        background: `transparent`,
        color: colors.color
      }}>
        <IconButton disableRipple disableTouchRipple edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{ marginLeft: `auto` }} size="large">
          <IoMdClose fontSize="large" />
        </IconButton>
      </AppBar>
      <DialogContent sx={{
        padding: 0,
        height: `auto`
      }}>
        <BodyLegal media={12} {...props} />
      </DialogContent>
    </Dialog>
  )
})
