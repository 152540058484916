'use client'
import { LoadingContext } from "@/context/tools/loading"
import { ToolsContext } from "@/context/tools/tools"
import { useEffectOnce } from "@/hooks/utils/useEffectOnce"
import { cmp } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { Box, Grid2, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import parse from "html-react-parser"
import { useContext, useId, useMemo } from "react"
const LegalTable = ({ ...props }) => {
  const keyId = useId()
  return (
    <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
      <Table aria-label={props.aria}>
        <TableBody>
          {props.data.map((x, i) => (
            <TableRow key={handleKeyRandom(keyId, i)}>
              <TableCell cmptype={cmp.stickyCell} align="right">
                {x.camp}
              </TableCell>
              <TableCell cmptype={cmp.styleCell} align="left">
                {parse(x.text)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const BodyLegal = ({ hostname }) => {
  const { mobile } = hostname
  const { tools: { texts }} = useContext(ToolsContext)
  const { modules } = useContext(LoadingContext)
  const privacyTexts = useMemo(() => texts.privacy_policy, [texts?.privacy_policy])
  const keyId = useId()

  useEffectOnce(() => {
    modules.handleLinkLoading(false)
  })

  return privacyTexts &&
    <Grid2
      container
      sx={{
        minHeight: !mobile ? `85vh` : `100vh`,
        alignContent: `flex-start`,
      }}
    >
      <Grid2 cmptype={cmp.gridLegal} size={{ xs: 12 }}>
        {privacyTexts?.purchase_terms && (
          <>
            {/* Purchase Terms Section */}
            <Typography cmptype={cmp.legalTitle} gutterBottom>
              {parse(privacyTexts.purchase_terms.title || ``)}
            </Typography>
            {privacyTexts.purchase_terms.text && (
              <Typography cmptype={cmp.legalText} gutterBottom>
                {parse(privacyTexts.purchase_terms.text)}
              </Typography>
            )}

            {/* Sections rendering using map */}
            {Object.entries(privacyTexts.purchase_terms).
              filter(([ , x ]) => x?.sequence !== undefined)?.
              sort(([ , a ], [ , b ]) => a.sequence - b.sequence)?.
              map(([ , sectionData ], idx) => {
                if (!sectionData) {
                  return null
                }

                return (
                  <Box key={`section_${idx}`}>
                    {sectionData.title && (
                      <Typography cmptype={cmp.legalSubTitle} gutterBottom>
                        {parse(sectionData.title)}
                      </Typography>
                    )}
                    {sectionData.text && (Array.isArray(sectionData.text) ?
                      sectionData.text.map((x, i) => (
                        <Typography cmptype={cmp.legalText} gutterBottom key={handleKeyRandom(`text`, i)}>
                          {parse(x)}
                        </Typography>
                      )) :
                      <Typography cmptype={cmp.legalText} gutterBottom>
                        {parse(sectionData.text)}
                      </Typography>
                    )}
                    {sectionData.data && (
                      <Box>
                        <ul>
                          {sectionData.data.map((item, index) => {
                            if (Array.isArray(item)) {
                              return (
                                <ul key={`ul_${handleKeyRandom(keyId, index)}`} style={{ listStyle: `lower-latin` }}>
                                  {item.map((subItem, subIndex) => (
                                    <li key={`li_${handleKeyRandom(keyId, subIndex)}`}>
                                      <Typography cmptype={cmp.legalText} gutterBottom>
                                        {parse(subItem)}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              )
                            }
                            return (
                              <li key={`li_${handleKeyRandom(keyId, index)}`}>
                                <Typography cmptype={cmp.legalText} gutterBottom>
                                  {parse(item)}
                                </Typography>
                              </li>
                            )
                          })}
                        </ul>
                      </Box>
                    )}
                  </Box>
                )
              })}
          </>
        )}

        {/* Privacy Section */}
        {privacyTexts?.title && (
          <Typography cmptype={cmp.legalTitle} gutterBottom>
            {parse(privacyTexts.title)}
          </Typography>
        )}
        {privacyTexts?.text && (
          <Typography cmptype={cmp.legalText} gutterBottom>
            {parse(privacyTexts.text)}
          </Typography>
        )}

        {/* Legal Tables Section */}
        {[ `owner`, `newsletter`, `account` ].map(section => {
          const sectionData = privacyTexts?.[section]
          if (!sectionData) {
            return null
          }

          return (
            <Box key={section}>
              <Typography cmptype={cmp.legalSubTitle} gutterBottom>
                {parse(sectionData.title || ``)}
              </Typography>
              <Typography cmptype={cmp.legalText} gutterBottom>
                {parse(sectionData.text || ``)}
              </Typography>
              {sectionData.data && (
                <LegalTable aria={`table-${section}`} data={sectionData.data} />
              )}
            </Box>
          )
        })}

        {/* Treatment Section */}
        {privacyTexts?.treatment && (
          <Box>
            <Typography cmptype={cmp.legalSubTitle} gutterBottom>
              {parse(privacyTexts.treatment.title || ``)}
            </Typography>
            <Typography cmptype={cmp.legalSubDoubleTitle} gutterBottom>
              {parse(privacyTexts.treatment.subtitle || ``)}
            </Typography>
            <Typography cmptype={cmp.legalText} gutterBottom>
              {parse(privacyTexts.treatment.text || ``)}
            </Typography>
            {privacyTexts.treatment.list?.map((x, i) => (
              <Box key={`box_${handleKeyRandom(keyId, i)}`}>
                <Typography cmptype={cmp.legalSubTitle} gutterBottom>
                  {parse(x.subTitle || ``)}
                </Typography>
                {x.text?.map((b, e) => Array.isArray(b) ? (
                  <ul key={`ul_${handleKeyRandom(keyId, e)}`} style={{ listStyle: `lower-latin` }}>
                    {b.map((c, f) => (
                      <li key={`li_${handleKeyRandom(keyId, f)}`}>
                        <Typography cmptype={cmp.legalText} gutterBottom>
                          {parse(c)}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography cmptype={cmp.legalText} key={`txt_${handleKeyRandom(keyId, e)}`} gutterBottom>
                    {parse(b)}
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
        )}
      </Grid2>
    </Grid2>
}
